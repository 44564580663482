<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else>
      <div class="row mb-3" v-if="agreements.length > 0">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <h5>Terms & Agreements</h5>
              <ul class="list-group">
                <li class="list-group-item bg-light cursor-pointer mb-3 border rounded"
                    v-for="agreement in agreements"
                    @click="viewTerm(agreement)">
                  <div class="row mb-2">
                    <div class="col my-auto">
                      {{agreement.term.title}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col my-auto">
                      <span v-if="agreement.client_responded" class="small">
                        <i class="fa fa-check-circle text-primary"></i>
                        <span v-if="agreement.client_responded_date">
                          You responded on {{agreement.client_responded_date | formatDate}}
                        </span>
                      </span>
                      <span v-else class="text-danger small">Awaiting Response</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" v-if="agreements.length === 0">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm">
            <div class="card-body py-4 text-center">
              <i class="fad fa-info-circle fa-5x text-primary mb-2"></i>
              <h3>No agreements found.</h3>
              <p>Agreements sent by your practitioner will show here.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: ['user'],
  data() {
    return {
      agreements: [],
      selectedTerm: null,
      busy: true,
    };
  },
  methods: {
    fetchAgreements() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/agreements")
        .then(({ data }) => {
          this.agreements = data.agreements;
          this.busy = false;
        });
    },
    viewTerm(term){
      this.$router.push("/agreements/" + term.id);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  },
  mounted() {
    this.fetchAgreements();
  }
};
</script>

<style>
</style>
